$(function(){
    // uuid取得
    var uuid = $('#uuid').val();
    var user_data = localStorage.getItem(`${uuid}`);

    console.log(user_data);
    if($.trim(user_data)==''){
      hash = {weekly_tweet: false, follower_numbers: false, impressions: false, engagement: false, profile_clicks: false, acquired_likes: false, earn_retweets: false};
      json = JSON.stringify(hash, undefined, 1);
      localStorage.setItem(`${uuid}`, json);
      user_data = localStorage.getItem(`${uuid}`);
    }

    user_items = JSON.parse(user_data, undefined, 1);

    if(user_items["weekly_tweet"] == true){
       $('input[name="weekly_tweet"]').prop("checked",true);
       $('.weekly_tweet').show();
    } else {
       $('.weekly_tweet').hide();
    }
    if(user_items["follower_numbers"] == true){
        $('input[name="follower_numbers"]').prop("checked",true);
       $('.follower_numbers').show();
    } else {
       $('.follower_numbers').hide();
    }
    if(user_items["impressions"] == true){
       $('input[name="impressions"]').prop("checked",true);
       $('.impressions').show();
    } else {
       $('.impressions').hide();
    }
    if(user_items["engagement"] == true){
        $('input[name="engagement"]').prop("checked",true);
       $('.engagement').show();
    } else {
       $('.engagement').hide();
    }
    if(user_items["profile_clicks"] == true){
        $('input[name="profile_clicks"]').prop("checked",true);
       $('.profile_clicks').show();
    } else {
       $('.profile_clicks').hide();
    }
    if(user_items["acquired_likes"] == true){
        $('input[name="acquired_likes"]').prop("checked",true);
       $('.acquired_likes').show();
    } else {
       $('.acquired_likes').hide();
    }
    if(user_items["earn_retweets"] == true){
        $('input[name="earn_retweets"]').prop("checked",true);
       $('.earn_retweets').show();
    } else {
       $('.earn_retweets').hide();
    }

    $('#weekly_tweet').on('click', function(){ 
        localstorage_setting(this, 'weekly_tweet', user_items, uuid);
    });
    $('#follower_numbers').on('click', function(){ 
        localstorage_setting(this, 'follower_numbers', user_items, uuid);
    });
    $('#impressions').on('click', function(){
        localstorage_setting(this, 'impressions', user_items, uuid);
    });
    $('#engagement').on('click', function(){
        localstorage_setting(this, 'engagement', user_items, uuid);
    });
    $('#profile_clicks').on('click', function(){
        localstorage_setting(this, 'profile_clicks', user_items, uuid);
    });
    $('#acquired_likes').on('click', function(){
        localstorage_setting(this, 'acquired_likes', user_items, uuid);
    });
    $('#earn_retweets').on('click', function(){
        localstorage_setting(this, 'earn_retweets', user_items, uuid);
    });

});

function localstorage_setting(selector, set, user_items, uuid) {
    if($(selector).prop('checked')){
        user_items[`${set}`] = true
        $(`.${set}`).show();
        $(`input.${set}_count`).prop('disabled', false);
     } else {
        user_items[`${set}`] = false
        $(`.${set}`).hide();
        $(`input.${set}_count`).prop('disabled', true);
    }
    var json = JSON.stringify(user_items, undefined, 1);
    localStorage.setItem(`${uuid}`, json);
}